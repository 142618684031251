<template>
  <div class="space-bottom">
    <b-row class="align-items-center">
      <b-col md="5" lg="4" xl="3" cols="12">
        <div class="d-flex mt-2">
          <p class="mt-2 mr-2 title">เลขที่</p>

          <InputText
            type="text"
            className=" bg-second flex-grow-1"
            placeholder="เลขที่"
            textField=""
            :value="form.document_no"
            disabled
          />
        </div>
      </b-col>
      <b-col md="5" lg="4" xl="3" cols="12">
        <div class="d-flex mt-2">
          <p class="mt-2 mr-2 title">วันที่</p>
          <InputText
            type="text"
            className=" bg-second flex-grow-1 "
            placeholder="วันที่"
            textField=""
            :value="$moment(form.created_time).format('DD/MM/YYYY (HH:mm)')"
            disabled
          />
        </div>
      </b-col>
      <b-col md="6" cols="12" v-if="id != 0">
        <div
          class="d-flex align-items-center justify-content-end"
          style="column-gap: 10px"
        >
          <div>สถานะ: {{ form.inventory_transfer_status_name }}</div>
          <div>
            อัพเดทล่าสุด:
            {{ $moment(form.updated_time).format("DD/MM/YYYY") }}
          </div>

          <b-button
            variant="custom-outline-primary"
            v-if="form.exportable"
            @click="exportDetail"
          >
            ออกรายงาน
          </b-button>
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col md="6">
        <p class="title">สาขาต้นทาง</p>

        <b-card class="mt-2">
          <InputText
            :value="sourceBranchDisplay"
            :disabled="
              documentDetail.force_select_login_branch_source ||
              (id != 0 && !form.isDraft)
            "
            type="text"
            :faIcon="
              documentDetail.force_select_login_branch_source ||
              (id != 0 && !form.isDraft)
                ? ''
                : 'search'
            "
            className=" mb-2 bg-second w-100"
            placeholder="ค้นหาสาขาต้นทาง"
            @selectAutoComplete="(val) => selectBranch(val, 1)"
            :optionsAutoComplete="documentDetail.source_branches"
            textField="branch_name"
            :optionValue="form.source_branch_id"
          >
          </InputText>

          <div
            class="d-flex mb-2"
            v-for="item in sourceBranch"
            :key="item.label"
          >
            <p class="text-label text-light-gray">{{ item.label }}</p>
            <p class="text-light-gray">:</p>
            <p>&nbsp;{{ form.source_branch[item.key] || "-" }}</p>
          </div>
        </b-card>
      </b-col>
      <b-col md="6">
        <p class="title">สาขาปลายทาง</p>
        <b-card class="mt-2">
          <InputText
            :value="destinationBranchDisplay"
            :disabled="
              documentDetail.force_select_login_branch_destination ||
              (id != 0 && !form.isDraft)
            "
            type="text"
            :faIcon="
              documentDetail.force_select_login_branch_destination ||
              (id != 0 && !form.isDraft)
                ? ''
                : 'search'
            "
            className=" mb-2 bg-second w-100"
            placeholder="ค้นหาสาขาปลายทาง"
            @selectAutoComplete="(val) => selectBranch(val, 2)"
            :optionsAutoComplete="documentDetail.destination_branches"
            textField="branch_name"
            @onClickIcon="onSearch"
            :optionValue="form.destination_branch_id"
          ></InputText>

          <div
            class="d-flex mb-2"
            v-for="item in destinationBranch"
            :key="item.label"
          >
            <p class="text-label text-light-gray">{{ item.label }}</p>
            <p class="text-light-gray">:</p>
            <p>&nbsp;{{ form.destination_branch[item.key] || "-" }}</p>
          </div>
        </b-card>
      </b-col>
      <b-col cols="12">
        <InputTextArea
          name="text"
          rows="4"
          v-model="form.remark"
          placeholder="หมายเหตุ"
          :disabled="!form.cancellable"
          textFloat="หมายเหตุ"
        />
      </b-col>
      <b-col cols="12">
        <p class="title mb-3">รายการสินค้า</p>
        <div class="card p-3">
          <b-table
            responsive
            striped
            hover
            show-empty
            empty-text="No matching records found"
            class="table-main table-custom"
            :no-border-collapse="false"
            :busy="isBusy"
            :items="items"
            :fields="fields"
          >
            <template v-slot:cell(index)="{ item }">
              {{ item.id + 1 }}
            </template>
            <template v-slot:cell(serial_name)="{ item }">
              <div v-if="item.is_serial">
                <p v-if="item.serial_name">
                  Serial : {{ item.serial_name }}
                  <span
                    v-if="form.cancellable"
                    class="text-link"
                    @click="selectSerial(item, item.id)"
                    >เปลี่ยน</span
                  >
                </p>
                <p
                  class="text-link"
                  v-else
                  @click="selectSerial(item, item.id)"
                >
                  กรุณาระบุ Serial
                </p>
              </div>
              <p v-else>-</p>
            </template>
            <template v-slot:cell(action)="{ item }">
              <font-awesome-icon
                v-if="form.cancellable"
                icon="trash-alt"
                title="delete-btn"
                size="lg"
                class="pointer text-primary icon-delete"
                @click.stop="openModalDelete(item.id)"
              />
            </template>
            <template v-slot:cell(product_image)="{ item }">
              <img :src="item.product_image" class="img-size" />
            </template>
            <template v-slot:cell(quantity)="{ item }">
              <InputText
                v-model="item.quantity"
                textFloat=""
                :disabled="item.is_serial ? true : false || !form.cancellable"
                type="number"
                placeholder="0"
              >
              </InputText>
            </template>
            <template v-slot:table-busy>
              <div class="text-center text-black my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong class="ml-2">Loading...</strong>
              </div>
            </template>
          </b-table>
          <Pagination
            @handleChangeTake="handleChangeTake"
            :pageOptions="pageOptions"
            :filter="filter"
            :rows="rows"
            @pagination="pagination"
          />
        </div>
      </b-col>
      <b-col>
        <p class="title my-3">ประวัติ</p>
        <div class="card p-3">
          <TableHistory :items="form.status_history" />
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import InputText from "@/components/inputs/InputText";
import InputTextArea from "@/components/inputs/InputTextArea.vue";
import Pagination from "@/components/Pagination";
import FooterAction from "@/components/FooterAction";
import TableHistory from "./components/TableHistory";

import { mapGetters } from "vuex";

export default {
  components: {
    InputText,
    InputTextArea,
    Pagination,
    FooterAction,
    TableHistory,
  },
  props: {
    id: { required: true },
  },
  data() {
    return {
      fields: [
        { label: "#", key: "index" },
        { label: "Image", key: "product_image", thStyle: { width: "10%" } },
        { label: "Barcode", key: "barcode" },
        {
          label: "Name",
          key: "product_name",
          tdClass: "text-left",
          thStyle: { width: "30%" },
        },
        { label: "Serial", key: "serial_name", thStyle: { width: "1ถ%" } },
        { label: "Quantity", key: "quantity", thStyle: { width: "10%" } },
      ],
      tabIndex: 0,
      isBusy: false,

      // form: {
      //   number: "",
      //   date: "",
      //   detail: "",
      // },
      // filter: {
      //     originalBranch: "",
      //     destinationBranch: "",
      //   },
      // },
      filter: {
        search: "",
        page: 1,
        take: 10,
      },
      sourceBranch: [
        {
          label: "ชื่อสาขา",
          key: "branch_name",
        },
        { label: "รหัส", key: "code" },
        { label: "ประเภท", key: "branch_type_name" },
        { label: "ผู้ส่ง", key: "action_by" },
        { label: "วันที่ส่งออก", key: "action_date" },
      ],
      destinationBranch: [
        {
          label: "ชื่อสาขา",
          key: "branch_name",
        },
        { label: "รหัส", key: "code" },
        { label: "ประเภท", key: "branch_type_name" },
        { label: "ผู้ส่ง", key: "action_by" },
        { label: "วันที่ส่งออก", key: "action_date" },
      ],
      productList: [],

      pageOptions: [
        { value: 5, text: "5 / page" },
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      items: [],
      rows: 0,

      form: {
        inventory_transfer_id: 0, //0 insert
        inventory_transfer_document_id: this.$route.params.id,
        source_branch_id: 0,
        destination_branch_id: 0,
        isDraft: false,
        remark: "",
        status_history: [],
        destination_branch: {
          branch_id: 0,
          branch_name: "",
          branch_short_name: "",
          code: "",
          branch_type_name: "",
          action_by: null,
          action_date: null,
        },
        source_branch: {
          branch_id: 0,
          branch_name: "",
          branch_short_name: "",
          code: "",
          branch_type_name: "",
          action_by: null,
          action_date: null,
        },
        cancellable: true,
        products: [],
      },
      isDraft: false,
      allItem: [],
      rowsFormData: [],
      filteredItems: [],
      searchDisplayProduct: "",
      documentDetail: { destination_branches: [], source_branches: [] },
      modalCallBack: () => {},
      modalMessage: "",
      sourceBranchDisplay: "",
      destinationBranchDisplay: "",
    };
  },
  mounted() {
    this.getDetail();
  },
  computed: {
    ...mapGetters({
      mainLoading: "getMainLoading",
      branchId: "getBranchId",
    }),
  },

  methods: {
    async getDetail() {
      console.log(this.id);
      this.$bus.$emit("showLoading");
      this.isBusy = true;
      const res = await this.axios(`/InventoryTransfer/History/${this.id}`);

      this.items = res.data.detail.products.map((product, index) => ({
        ...product,
        id: index,
      }));
      this.form = res.data.detail;
      this.allItem = this.items;

      this.selectBranch(res.data.detail.source_branch, 1);

      this.selectBranch(res.data.detail.destination_branch, 2);
      this.rows = this.allItem.length;
      this.filteredItems = this.allItem;
      this.updateDisplayedItems();
      this.isBusy = false;
      this.$bus.$emit("hideLoading");
    },
    async getDocumentDetail() {
      this.$bus.$emit("showLoading");

      const res = await this.axios(
        `/Inventory/Document/${this.$route.params.id}`
      );
      this.$bus.$emit("hideLoading");
      if (!this.form.allow_create_destination && !this.form.allow_create_source)
        return this.errorAlert("ไม่มีสิทธิ์ในการสร้าง Document นี้").then(() =>
          this.$router.replace("/inventory-transfer/" + this.$route.params.id)
        );
      this.documentDetail = this.form;
      if (this.form.force_select_login_branch_source)
        this.selectBranch(this.form.source_branches[0], 1);
      if (this.form.force_select_login_branch_destination)
        this.selectBranch(this.form.destination_branches[0], 2);
    },
    async saveForm() {
      let products = this.allItem.map((product) => ({
        product_id: product.product_id,
        quantity: product.quantity,
        serial_id: product.serial_id || null,
        serial_name: product.serial_name,
      }));
      this.$bus.$emit("showLoading");

      let payload = {
        ...this.form,
        isDraft: this.isDraft,
        products: [...products],
      };
      this.axios
        .post(`/Inventory/Transfer/Save`, payload)
        .then(async (data) => {
          this.$bus.$emit("hideLoading");
          if (data.result == 1) {
            this.successAlert();
            this.$router
              .push({
                path: `/inventory-transfer/${this.$route.params.id}`,
              })
              .catch(() => {});
          } else {
            this.warningAlert(data.message);
          }
        });
    },
    async searchProduct() {
      const res = await this.axios.post(
        `/Inventory/Transfer/SearchProduct`,
        this.filter
      );
      if (this.form.look_up_product) {
        this.setProductSelect(this.form.look_up_product);
        return;
      }
      this.productList = this.form.search_product;
    },
    onSearch() {},
    setProductSelect(product) {
      const productIndex = this.allItem.findIndex(
        (item) => item.product_id === product.product_id
      );

      const dupeSerial = this.allItem.findIndex(
        (item) => item.serial_name == product.serial
      );

      if (productIndex !== -1 && !product.is_serial) {
        this.allItem[productIndex].quantity += 1;
      } else {
        let _product = {
          product_image: product.image,
          barcode: product.barcode,
          product_name: product.product_name,
          quantity: 1,
          serial_name: product.serial,
          is_serial: product.is_serial,
          serial_id: product.inventory_product_serial_id,
          product_id: product.product_id,
          id: this.allItem.length,
        };

        this.allItem.push({ ..._product });
        this.filter.page = 1;
        this.updateDisplayedItems();
      }

      const maxItems = this.filter.take;

      if (this.allItem.length > maxItems) {
        this.items = this.allItem.slice(0, maxItems);
      } else {
        this.items = this.allItem;
      }
      this.rows = this.allItem.length;
      this.filteredItems = this.allItem;
    },
    selectBranch(val, type) {
      const select = () => {
        let display = `${val.code} ${val.branch_name}`;
        if (type == 1)
          (this.sourceBranchDisplay = display),
            (this.form.source_branch = val),
            (this.form.source_branch_id = val.branch_id);
        if (type == 2)
          (this.destinationBranchDisplay = display),
            (this.form.destination_branch = val),
            (this.form.destination_branch_id = val.branch_id);
        this.$nextTick();
      };

      select();
    },
    openModalDelete(id) {
      this.confirmAlert("Confirm delete product?").then((val) => {
        if (val.isConfirmed) {
          this.deleteProduct(id);
        }
      });
    },
    deleteProduct(item) {
      this.allItem = this.allItem.filter((x) => x.id !== item);

      this.filteredItems = this.filteredItems.filter(
        (x, index) => x.id !== item
      );
      this.updateDisplayedItems(true);
    },
    handleSearchDisplayProduct() {
      const searchText = this.searchDisplayProduct.toLowerCase();
      if (searchText) {
        this.filteredItems = this.allItem.filter(
          (item) =>
            item.product_name.toLowerCase().includes(searchText) ||
            item.barcode.toLowerCase().includes(searchText)
        );
      } else {
        this.filteredItems = [...this.allItem];
      }
      this.filter.page = 1;
      this.updateDisplayedItems();
    },
    pagination(val) {
      this.filter.page = val;
      this.updateDisplayedItems();
    },
    handleChangeTake() {
      this.filter.page = 1;
      this.updateDisplayedItems();
    },
    updateDisplayedItems(isDelete) {
      this.isBusy = true;
      if (isDelete) {
        this.filter.page = 1;
      }
      const start = (this.filter.page - 1) * this.filter.take;
      const end = start + this.filter.take;

      this.items = this.filteredItems.slice(start, end);
      this.rows = this.filteredItems.length;
      this.isBusy = false;
    },

    async exportDetail() {
      this.$bus.$emit("showLoading");
      const res = await this.axios(
        `/InventoryTransfer/History/${this.form.id}/export`,

        {
          responseType: "blob",
        }
      );

      let data = res;

      var fileURL = window.URL.createObjectURL(new Blob([data.data]));
      var fileLink = document.createElement("a");
      // var dateExcel = this.$moment().format("DDMMYYYYhhmmss");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", this.form.document_no + `.xlsx`);
      document.body.appendChild(fileLink);
      fileLink.click();
      this.$bus.$emit("hideLoading");
    },
  },
};
</script>
<style lang="scss" scoped>
.w-85 {
  width: 85%;
}

.title {
  color: #333 !important;
  font-size: 16x !important;
  font-weight: bold !important;
  position: relative;
}

.text-label {
  width: 100px;
}

.text-light-gray {
  color: #9b9b9b;
}

::v-deep .input-group-text {
  background-color: var(--primary-color) !important;
  color: white !important;
}

// .custom-add-product ::v-deep .input-custom input[data-v-32b8d6e1] {
//   border-radius: 0px !important;
// }

.custom-height-bt {
  height: 38.66px !important;
}

.img-size {
  width: 60px;
  height: 60px;
}

.w-30 {
  width: 30%;
}

// ::v-deep .c-main > .container-fluid {
//   height: calc(100vh - 80px) !important;
//   overflow: scroll !important;
// }
.space-bottom {
  padding-bottom: 80px;
}
</style>
